<template>
  <div class="resettop">
    <div class="re_content">
      <div class="re_center">
        <img :src="redLogoImg" alt="">

        <div style="margin: 0 18px;">|</div>

        <div class="re_alter">
          密码重置
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import conf from "@/utils/conf"
  export default {
    name: 'ResetTop',
    data() {
      return {
        redLogoImg:conf.getRedLogoImg()
      }
    },
    methods: {},
    components: {}
  }
</script>

<style lang="less" scoped>
.resettop {
  background-color: #fff;
  height: 50px;
  width: 100%;
  .re_content {
    width: 65%;

    margin: 0 auto;
    .re_center {
      color: red;
      line-height: 50px;
      width: 22%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      img{
        height: 30px;
      }
      .re_alter {
        min-width: 100px;
      }

    }

  }
}

</style>
